import React, { useEffect, useState } from 'react';
import { AnnouncementBar } from '@screentone/core';

interface AnnouncementBarProps {
  id: string;
  content: string | React.ElementType;
}

const AnnouncementBarComponent: React.FC<AnnouncementBarProps> = ({ id, content }) => {
  const [dismissed, setDismissed] = useState(false);
  const STORAGE_ID_DEFAULT = `st-announcement-${id}`;

  useEffect(() => {
    function getStorage() {
      const item = JSON.parse(localStorage.getItem(STORAGE_ID_DEFAULT) || '{}');
      if (item.dismissed !== dismissed) {
        setDismissed(item.dismissed)
      }
    }
    window.addEventListener('storage', getStorage);
    return () => {
      window.removeEventListener('storage', getStorage);
    }
  }, [])
  
  console.log('dismissed: ', dismissed);
  if (dismissed) {
    return null;
  }
  
  return (
    <AnnouncementBar
      onDismiss={() => {
        setDismissed(true);
        localStorage.setItem(STORAGE_ID_DEFAULT, JSON.stringify({ dismissed: true }));
      }}>
        {content}
    </AnnouncementBar>
  );
};

export default AnnouncementBarComponent;
export { AnnouncementBarComponent, AnnouncementBarProps};
