import get from 'lodash/get';

import { User } from '../../types';

function checkUserAccess(
  user: User,
  permission: string,
  userAccessKey: string = 'app_scope'
) {
  if (!user) {
    return false;
  }

  const isUserTester = get(user, 'app_tester', false);
  // @ts-ignore
  const userAppScope: string[] = isUserTester
    ? []
    : get(user, userAccessKey, []);
  return Boolean(userAppScope.includes(permission) || user[permission]);
}

export { checkUserAccess };
