import React from 'react';
import { PageWrapperProps } from '../../types';

const PageWrapper: React.FC<PageWrapperProps> = ({
  PageWrapperElement,
  children,
  ...props
}) => {
  if (PageWrapperElement) {
    return <PageWrapperElement {...props}>{children}</PageWrapperElement>;
  }

  return (
    <div className="st_content" {...props}>
      {children}
    </div>
  );
};

export default PageWrapper;
