import React from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import compareVersions from 'compare-versions';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import get from 'lodash/get';

import AuthProvider from '../providers/Auth';
import HeaderBarComponent from '../components/HeaderBar';
import PageWrapperComponent from '../components/PageWrapper';
import AnnouncementBar from '../components/AnnouncementBar';
import ErrorPage from '../pages/Error';
import { ERRORS } from '../pages/Error/Error.constants'; //  USER_REQUEST_OPTIONS

import LoadingPage from '../pages/Loading';
import LoginPage from '../pages/Login';
import LogoutPage from '../pages/Logout';
import HomePage from '../pages/Home';
import ProtectedRoute from './ProtectedRoute';
import LoginCallback from './LoginCallback';
import { validateConfig } from './AuthRouter.utils';
import { AppConfig, OktaAuthService, HeaderBarObject } from '../types';

const currentVersion = '0.0.35'; // TODO: update to get version from package.json

interface AuthRouterProps {
  /**
   * Base details of your app, like your App name.
   * [See App Props page for details](./?path=/docs/authrouter-app-props--page)'
   */
  app: AppConfig;
  /**
   * Base configuration options required by okta.
   * [See Okta Auth Service Props for details](./?path=/docs/authrouter-okta-auth-service-props--page)
   */
  authConfig: OktaAuthService;
  /**
   * Override this component\'s page wrapper.
   */
  pageWrapper?: any;
  /**
   * An optional secondary provider or component to wrap the whole app
   */
  provider?: React.ElementType;
  /**
   * Override this component's header bar. If you use this option the default header 
   * will not load and you will need to specify your own header component.
   * Any Props sent in the headerBar object will be ignored. 
   */
  headerBarOverride?: any;
  /**
   * Returns headerBar object. Any Props sent in the headerBar object will be ignored
   */
  headerBarHook?: any;
  /**
   * Customize the header bar with user menu links, notifications & settings.
   * [See HeaderBar Props page for details](./?path=/story/authrouter-header-bar-props--page)
   */
  headerBar?: HeaderBarObject;
  /**
   * Override the default property redirects after a successful login.
   * If you are not using the property functionality of this addon then this is required.
   */
  homePage?: React.ElementType;
  /**
   * Override the default login component before a successful login.
   */
  loginPage?: React.ElementType;
  /**
   * 'If set to `true`, the user will be directed to a custom login page.
   * This should only be used if rendering inside an iFrame or for test automation.',
   */
  useCustomLoginPage?: boolean;
}

const AuthRouter: React.FC<AuthRouterProps> = ({
  app,
  authConfig,
  useCustomLoginPage = false,
  provider,
  pageWrapper,
  headerBar,
  headerBarOverride,
  headerBarHook,
  homePage,
  loginPage,
  children,
}) => {
  const isValid = validateConfig({ app, authConfig });

  if (!isValid) {
    return <ErrorPage type="412" app={app} />;
  }

  const savedVersion: string = localStorage.getItem('st-aw-version') || '0.0.0';
  if (app.maintenance && app.maintenance.active) {
    return <ErrorPage type="maintenance" app={app} />;
  }

  // TODO: Move to utils
  function cleanStoredOktaItems(
    obj: Storage | { removeItem?: any },
    isCookie?: boolean
  ) {
    Object.keys(obj).map(key => {
      if (key.includes('okta')) {
        isCookie
          ? (document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`)
          : obj.removeItem(key);
      }
    });
  }

  const clearOktaStorage = compareVersions.compare(
    currentVersion,
    savedVersion,
    '>'
  ); // return true

  if (clearOktaStorage) {
    const cookies = document.cookie.split(';').reduce((ac, str) => {
      const arr = str.trim().split('=');
      return { ...ac, [arr[0]]: arr[1] };
    }, {});

    cleanStoredOktaItems(localStorage);
    cleanStoredOktaItems(sessionStorage);
    cleanStoredOktaItems(cookies, true);

    localStorage.setItem('st-aw-version', currentVersion);
  }

  const AuthHandler = () => {
    window.location.assign(`${window.location.origin}/login`);
  };

  const expireEarlySeconds = get(authConfig, 'tokenManager.expireEarlySeconds');

  if (!expireEarlySeconds) {
    authConfig.tokenManager = authConfig.tokenManager || {};
    authConfig.tokenManager.expireEarlySeconds = 120;
  }

  function ErrorRoute() {
    const { type } = useParams<{ type: keyof typeof ERRORS | any }>();
    return <ErrorPage type={type} app={app} />;
  }

  function Error404Route() {
    return <ErrorPage type="404" app={app} />;
  }

  function HomePageRoute() {
    return <HomePage HomePage={homePage} LoginPage={loginPage} />;
  }

  let propertyPath = '';

  if (app.properties) {
    propertyPath =
      app.properties.length === 1
        ? `${app.properties[0].key}`
        : `:property(${app.properties
            .map((property: { key: string }) => property.key)
            .join('|')})`;
  }

  const childRoutes = React.Children.toArray(children).map(child => {
    if (React.isValidElement(child)) {
      const currentProps = child.props;
      const newProps: any = {};
      Object.keys(currentProps).forEach(key => {
        if (typeof currentProps[key] === 'string') {
          newProps[key] = currentProps[key].replace(':property', propertyPath);
        } else {
          newProps[key] = currentProps[key];
        }
      });
      return React.cloneElement(child, newProps);
    }
    return null;
  });

  const oktaAuth = new OktaAuth(authConfig);

  const SecondaryProvider = provider || 'span';
  const CustomLogin: React.FC = () => (
    <LoginPage config={authConfig} app={app} />
  );

  return (
    <Router>
      {app.maintenance && app.maintenance.announcement && (
        <AnnouncementBar
          id={app.maintenance.announcement.id}
          content={app.maintenance.announcement.content}
        />
      )}
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={useCustomLoginPage ? AuthHandler : null}>
        <AuthProvider app={app}>
          <SecondaryProvider>
            <HeaderBarComponent
              HeaderBarElement={headerBarOverride}
              HeaderBarObject={headerBar}
              HeaderBarFunction={headerBarHook}
            />
            <PageWrapperComponent PageWrapperElement={pageWrapper}>
              <React.Suspense fallback={<LoadingPage />}>
                <Switch>
                  {childRoutes}
                  <Route
                    exact
                    path="/(index.html)?"
                    component={HomePageRoute}
                  />
                  <Route path="/implicit/callback" component={LoginCallback} />
                  {useCustomLoginPage && (
                    <Route exact path="/login" component={CustomLogin} />
                  )}
                  <Route path="/login/callback" component={LoginCallback} />
                  <Route path="/logout" component={LogoutPage} />
                  <Route exact path="/error/:type?" component={ErrorRoute} />
                  <Route path="*" component={Error404Route} />
                </Switch>
              </React.Suspense>
            </PageWrapperComponent>
          </SecondaryProvider>
        </AuthProvider>
      </Security>
    </Router>
  );
};

export { AuthRouter, ProtectedRoute };
