import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { OktaErrorPageProps } from '../../types';
import { useAuth } from '../../providers/Auth';

const ErrorPage: React.FC<OktaErrorPageProps> = ({ error }) => {
  console.error('Okta OAuthError: ', error);

  if (error.name === 'OAuthError' && error.errorCode) {
    return <Redirect to={`/error/${error.errorCode}`} />;
  }
  return <Redirect to="/error" />;
};

const LoginCallback: React.FC<{
  errorComponent?: React.ComponentType<{ error: Error }>;
}> = ({ errorComponent }) => {
  const [error, setError] = useState(false);
  const { oktaAuth, authState } = useAuth();
  const authStateReady = !authState.isPending;

  const ErrorReporter = errorComponent || ErrorPage;

  useEffect(() => {
    oktaAuth
      .handleLoginRedirect()
      .catch((error: any) => {
        console.group('handleLoginRedirect error: ')
        console.error('error: ', error);
        if (error) {
          console.log('name: ', error.name || 'N/A');
          console.log('errorSummary: ', error.errorSummary || 'N/A');
          console.log('errorCode: ', error.errorCode || 'N/A');
          console.log('errorLink: ', error.errorLink || 'N/A');
          console.log('errorId: ', error.errorId || 'N/A');
          console.log('errorCauses: ', error.errorCauses || 'N/A');
        }
        console.groupEnd();
        setError(error);
      });
  }, [oktaAuth]);
  
  if (authStateReady && authState.error || error) {
    return (
      <ErrorReporter error={authState.error || error} />
    );
  }

  return null;
};

export default LoginCallback;
