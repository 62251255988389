import React from 'react';
import UserMenu from './UserMenu';
import NotificationsMenu from './NotificationsMenu';
import { HeaderBar } from '@screentone/core';
import { useAuth } from '../../providers/Auth';
import { HeaderBarProps } from '../../types';

const HeaderBarComponent: React.FC<HeaderBarProps> = ({
  HeaderBarElement,
  HeaderBarObject,
  HeaderBarFunction,
  children,
  ...props
}) => {
  const { app, user, currentProperty, userAccess } = useAuth();

  let headerObj;
  if (HeaderBarFunction) {
    headerObj = HeaderBarFunction()[0];
  } else {
    headerObj = HeaderBarObject;
  }

  if (window.location.pathname === '/login') {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (HeaderBarElement) {
    return <HeaderBarElement {...props}>{children}</HeaderBarElement>;
  }
  const appEnv = ['prod', 'production'].includes((app.env || '').toLowerCase())
    ? null
    : app.env;

  return (
    <React.Fragment>
      {headerObj && headerObj.alert}
      <HeaderBar sticky={headerObj && headerObj.sticky}>
        <HeaderBar.Branding href="/" componentEl="a" env={appEnv}>
          {app.name}
        </HeaderBar.Branding>
        {headerObj && headerObj.links && (
          <HeaderBar.Links>
            {headerObj.links.map((link: any) => {
              const { text, href, restricted, ...props } = link;
              if (!restricted || (restricted && userAccess(restricted))) {
                return (
                  <HeaderBar.Links.Item key={href || text} href={href} {...props}>
                    {text}
                  </HeaderBar.Links.Item>
                );
              } else {
                return;
              }
            })}
          </HeaderBar.Links>
        )}
        {user && headerObj && headerObj.notifications && (
          <NotificationsMenu notifications={headerObj.notifications} />
        )}
        {user && <UserMenu HeaderBarObject={headerObj} />}
        {user && user.app_properties.length > 1 && currentProperty && (
          <HeaderBar.Product type={currentProperty} />
        )}
      </HeaderBar>
    </React.Fragment>
  );
};

export default HeaderBarComponent;
