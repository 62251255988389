import React, { useEffect, useState } from 'react';
import { Alert, Button, Login, PageWrapper } from '@screentone/core';
import LoadingPage from '../Loading';
import ErrorPage from '../Error';

import { useAuth } from '../../providers/Auth';

const LogoutPage: React.FC = () => {
  const { app, user, login, authState, oktaAuth, oktaSignOut, appLogout } = useAuth();
  const [isPending, setIsPending] = useState(true);
  const [loggedOutState, setLoggedOutState] = useState('pending');
  const [hasError, setError] = useState(false);
  const [errorMsg, setErrorMgs] = useState(null);
  let state = '';

  switch (window.location.search) {
    case '?state=okta':
      state = 'okta';
      break;
    case '?state=app':
      state = 'app';
      break;
    default:
      break;
  }


  useEffect(() => {
    oktaAuth.session.exists()
      .then((exists: any) => {
        
        if (exists) {
          if (state === 'okta') {
            throw 'Failed to sign out of Okta';
          } else if (state === 'app') {
            if (authState.isAuthenticated) {
              throw `Failed to logout of Okta ${app.name}`;
            } else {
              setError(false)
              setLoggedOutState(state);
            }
          } else {
            throw 'unknown state';
          }
        } else {
          setError(false)
          setLoggedOutState('okta');
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMgs(error);
        setError(true);
        setLoggedOutState('error');
      })
      .finally(() => {
        if (app.onLogout) {
          let logoutState = { state: loggedOutState, error: hasError, errorMsg };
          
          app.onLogout(logoutState);
        }
        setIsPending(false)
      });
    
  }, [user, authState, oktaAuth]);

  if (isPending) {
    return <LoadingPage />;
  }
  if (!hasError) {
    return (
      <React.Fragment>
        <Alert type="success" icon="check-circle" fullWidth>
          {loggedOutState === 'okta' && `You have been successfully signed out of Okta.`}
          {loggedOutState === 'app' && (
            <span>
              You have been successfully logged out of {app.name},{' '}
              but you are still signed in to okta.{' '}
              <Button tertiary onClick={() => oktaSignOut()}>Sign out of Okta</Button>
            </span>
          )}
        </Alert>
        <PageWrapper>
          <Login
          name={app.name}
          illustration={app.illustration || 'no-access'}
          onClick={login}
        />
      </PageWrapper>
      </React.Fragment> 
    );
  }

  return (
    <React.Fragment>
      <Alert type="error" icon="error" fullWidth>
        <span>
          You are NOT logged out!{' '}
          Try again -{' '}
          <Button tertiary onClick={() => appLogout}>Logout of {app.name}</Button>
          {' or '}
          <Button tertiary onClick={() => oktaSignOut}>Sign out of Okta</Button>
        </span>
      </Alert>
      <ErrorPage type={"400"} app={app} />
    </React.Fragment>
  );
};

export default LogoutPage;